import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { ReactComponent as ChevronIcon } from 'src/assets/chevron.svg';
import { FilterOptionType } from '../../../shared/models';
import FilterDropdown from './FilterDropdown';
import s from './filters.module.scss';

interface Props {
  filter: FilterOptionType;
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterOptionType[]>>;
}

const ActiveFilterItem: React.FC<Props> = ({ filter, setActiveFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => setIsOpen(false),
  });
  return (
    <div ref={ref} className={s.option_wrapper}>
      <button onClick={() => setIsOpen(!isOpen)} className={s.option}>
        <span>
          {filter.name} is {filter.value.operator} <b>{filter.value.value1}</b>
          {filter.value.value2 && (
            <>
              {' '}
              and <b>{filter.value.value2}</b>
            </>
          )}
        </span>
        <ChevronIcon className={s.option_icon} />
      </button>
      {isOpen && (
        <div className={s.option_inner}>
          <FilterDropdown
            handleDelete={() => {
              setActiveFilters((fil) => {
                let copy = cloneDeep(fil);
                const exi = copy.findIndex((ex) => ex.id === filter.id);
                copy.splice(exi, 1);
                return copy;
              });
              setIsOpen(false);
            }}
            filter={filter}
            handleCancel={() => setIsOpen(false)}
            handleConfirm={(e) => {
              setActiveFilters((fil) => {
                const copy = cloneDeep(fil);
                const exi = copy.findIndex((ex) => ex.id === e.id);
                copy[exi] = e;
                return copy;
              });
              setIsOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveFilterItem;
