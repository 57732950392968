import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as GoogleLogo } from '../../assets/google-logo.svg';
import { ReactComponent as PaperboxLogo } from '../../assets/paperbox-logo.svg';
import { useAuth } from '../../shared/hooks/useAuth';
import authSlice, { signInWithGoogle } from '../../store/authSlice';
import { useDispatch } from '../../store/store';
import s from './login.module.scss';

enum SignInState {
  'init',
  'authenticating',
  'authenticated',
  'verified',
}

interface Props {}

const Login: React.FC<Props> = (props) => {
  const [logInState, setLogInState] = useState<SignInState>(0);
  const { authError, isAuthenticating } = useAuth();

  const dispatch = useDispatch();

  const handleLogin = async () => {
    setLogInState(SignInState.authenticating);
    dispatch(authSlice.actions.setAuthError(null));

    await dispatch(signInWithGoogle())
      .then((res) => {
        res?.user.getIdTokenResult(true).then((tokenRes) => {
          if (tokenRes.claims['superUser'] || tokenRes.claims['viewer']) {
            setLogInState(SignInState.authenticated);
          } else {
            setLogInState(SignInState.init);
          }
        });
      })
      .catch(() => {
        dispatch(authSlice.actions.setAuthError('Cancelled'));
        setLogInState(SignInState.init);
      });
  };

  useEffect(() => {
    if (logInState === SignInState.authenticated && !authError && !isAuthenticating) {
      setLogInState(SignInState.verified);
      setTimeout(() => {
        dispatch(authSlice.actions.setIsLoggedIn(true));
      }, 1000);
    }
  }, [dispatch, authError, isAuthenticating, logInState]);

  return (
    <div className={s.container}>
      <div
        className={clsx(s.card, {
          [s.card__expanded]: logInState === SignInState.verified,
        })}
      >
        <div className={s.logoGroup}>
          <PaperboxLogo />
          <span>OLYMPUS</span>
        </div>
        <button
          onClick={handleLogin}
          className={clsx(
            s.button__signIn,
            { [s.button__signIn__pending]: logInState === SignInState.authenticating },
            { [s.button__signIn__hidden]: logInState === SignInState.verified }
          )}
        >
          <div className={s.logoBackground} />
          <div className={s.buttonContent}>
            <div className={s.googleLogo}>
              <GoogleLogo />
            </div>
            <span>Continue with Google</span>
          </div>
        </button>
        {authError && <div className={s.error}>{authError}</div>}
      </div>
    </div>
  );
};

export default Login;
