import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, NotificationType } from '../shared/models';

interface NotificationState {
  notification?: Notification;
  notificationContent?: string;
  notificationType?: NotificationType;
  isActive: boolean;
}

const initialState: NotificationState = {
  isActive: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setNotificationContent: (state, action: PayloadAction<string>) => {
      state.notificationContent = action.payload;
    },
    setNotificationType: (state, action: PayloadAction<NotificationType>) => {
      state.notificationType = action.payload;
    },
  },
});
