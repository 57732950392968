import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as TrashIcon } from '../../../assets/delete.svg';
import { FilterOptionType } from '../../../shared/models';
import DateFilter from './DateFilter';
import s from './filters.module.scss';
import StringFilter from './StringFilter';

interface Props {
  filter: FilterOptionType;
  handleCancel: VoidFunction;
  handleConfirm: (newFilter: FilterOptionType) => void;
  handleDelete?: VoidFunction;
}

const FilterDropdown: React.FC<Props> = ({
  filter,
  handleCancel,
  handleConfirm,
  handleDelete,
}) => {
  const [editableOption, setEditableOption] = useState(filter);
  return (
    <div className={s.option_dropdown}>
      {filter.type === 'date' && (
        <DateFilter
          option={filter}
          selectedOption={editableOption}
          setSelectedOption={setEditableOption}
        />
      )}
      {filter.type === 'option' && (
        <StringFilter
          option={filter}
          selectedOption={editableOption}
          setSelectedOption={setEditableOption}
        />
      )}
      <div className={s.buttons}>
        {filter.value && (
          <button onClick={handleDelete} className={clsx(s.button, s.button__icon)}>
            <TrashIcon />
          </button>
        )}
        <button className={s.button} onClick={handleCancel}>
          Cancel
        </button>
        <button
          className={clsx(s.button, s.button__alt)}
          onClick={() => handleConfirm(editableOption)}
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export default FilterDropdown;
