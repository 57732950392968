import { useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getInfo,
  listenForPendingDocs,
  listenForPendingMutationDocs,
} from '../../store/documentsSlice';
import { useDispatch, useSelector } from '../../store/store';
import { FilterOptionType } from '../models';

export const usePendingDocuments = (canFetch: boolean, filters: FilterOptionType[]) => {
  const documentList = useSelector((state) => state.documents.documentList);
  const mutationList = useSelector((state) => state.documents.mutationList);
  const provisioningList = useSelector((state) => state.documents.provisioningList);
  const structureLoaded = useSelector((state) => state.documents.structureLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (canFetch && !structureLoaded) {
      dispatch(getInfo());
    }
  }, [canFetch, dispatch, structureLoaded]);

  useDeepCompareEffect(() => {
    if (structureLoaded && filters) {
      dispatch(listenForPendingDocs(filters));
      dispatch(listenForPendingMutationDocs(filters));
    }
  }, [dispatch, structureLoaded, filters]);

  return {
    mutationList,
    documentList,
    provisioningList,
  };
};
