import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ReactComponent as ClipboardIcon } from '../../assets/clipboard.svg';
import { ReactComponent as CloudIcon } from '../../assets/cloud.svg';
import { ReactComponent as FirebaseIcon } from '../../assets/firebase.svg';
import { useNotification } from '../../shared/hooks/useNotification';
import { MasterDataVersion } from '../../shared/models';
import { useSelector } from '../../store/store';
import d from '../pending-documents/pending-documents.module.scss';
import s from './versions.module.scss';

interface Props {
  version: MasterDataVersion;
}

const VersionRow: React.FC<Props> = ({ version }) => {
  const tables = useSelector((state) => state.masterdata.tables);
  const { setContent, showNotification } = useNotification();

  const tableName = useMemo(() => {
    if (tables) {
      const table = tables.find((e) => e.id === version.tableId);
      return table?.name ?? '';
    }
  }, [tables, version.tableId]);

  const handleClipboard = () => {
    navigator.clipboard.writeText(version.tableId);
    setContent(`Copied table ID to clipboard`);
    showNotification();
  };
  const handleVersionClipboard = () => {
    navigator.clipboard.writeText(version.id);
    setContent(`Copied version ID to clipboard`);
    showNotification();
  };
  const handleTenantClipboard = () => {
    navigator.clipboard.writeText(version.tenantId);
    setContent(`Copied tenant ID to clipboard`);
    showNotification();
  };
  const handleCloud = () => {
    const proj = process.env.REACT_APP_FIREBASE_PROJECT_ID;

    const url = `https://console.cloud.google.com/storage/browser/${version.rawGcsPath.replace(
      'gs://',
      ''
    )}?authuser=0&project=${proj}`;
    if (version.rawGcsPath) window.open(url, '_blank').focus();
  };
  const handleFirebase = () => {
    const proj = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    let url = `https://console.firebase.google.com/project/${proj}/firestore/data/${version.fullDocPath}`;

    window.open(url, '_blank').focus();
  };

  const statusIcon = useMemo(() => {
    if (version.status === 'ready') {
      return (
        <div
          style={{
            background: '#ADE01B',
            boxShadow: '0px 0px 4px #ADE01B',
          }}
        />
      );
    } else if (version.status === 'deleted') {
      return (
        <div
          style={{
            background: '#4a4a51',
            boxShadow: '0px 0px 4px #4a4a51',
          }}
        />
      );
    } else if (version.status === 'failed') {
      return (
        <div
          style={{
            background: '#FF5555',
            boxShadow: '0px 0px 4px #FF5555',
          }}
        />
      );
    } else if (version.status === 'ingesting') {
      return (
        <div
          style={{
            background: '#0085FF',
            boxShadow: '0px 0px 4px #0085FF',
          }}
        />
      );
    }
  }, [version.status]);

  return (
    <div className={s.row}>
      <div className={clsx(s.first, s.icon)}>{statusIcon}</div>
      <div className={clsx(s.second, d.flex_col)}>
        <button
          className={clsx(d.document_item_tenant_name, d.document_item_row_item)}
          onClick={handleVersionClipboard}
        >
          <span>{version?.id}</span>
          <div className={d.hover_icon}>
            <ClipboardIcon />
          </div>
        </button>
      </div>
      <div className={clsx(s.fourth, d.flex_col)}>
        <button
          className={clsx(d.document_item_tenant_name, d.document_item_row_item)}
          onClick={handleTenantClipboard}
        >
          <span>{version?.tenantId}</span>
          <div className={d.hover_icon}>
            <ClipboardIcon />
          </div>
        </button>
      </div>
      <div className={s.third}>
        <span>{tableName}</span>
      </div>

      <div className={s.fifth}>
        <span>{version.uploadTime.toLocaleString()}</span>
      </div>
      <div className={s.sixth}>
        <div className={s.row_actions}>
          <button
            data-tip
            data-for={`copy`}
            onClick={handleClipboard}
            className={s.row_action}
          >
            <ClipboardIcon />
          </button>

          <button
            data-tip
            data-for={`cloud`}
            onClick={handleCloud}
            className={s.row_action}
          >
            <CloudIcon />
          </button>
          <button
            data-tip
            data-for={`firestore`}
            onClick={handleFirebase}
            className={clsx(s.row_action)}
          >
            <FirebaseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VersionRow;
