import React, { useState } from 'react';
import { Ring } from '@uiball/loaders';
import clsx from 'clsx';
import Modal from 'react-modal';
import { reprocessInbox } from '../../../store/documentsSlice';
import { Inbox } from '../../models';
import Checkbox from '../checkbox/Checkbox';
import Dropdown from '../dropdown/Dropdown';
import s from './modal.module.scss';

interface Props {
  inboxes: Inbox[];
  tenantId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ReprocessModal: React.FC<Props> = ({ tenantId, inboxes, isOpen, setIsOpen }) => {
  const [workflow, setWorkflow] = useState({ label: 'Main', value: 'main' });
  const [reprocessParams, setReprocessParams] = useState({
    doc_type_id: false,
    doc_subtype_id: false,
    tag_type_id: false,
    entities: false,
    'metadata.table': false,
  });
  const isAllChecked = Object.values(reprocessParams).every((x) => x);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    var promiseList = [];
    setIsLoading(true);
    var clearList = Object.entries(reprocessParams)
      .filter((x) => x[1])
      .map((x) => x[0]);
    inboxes.forEach((inbox) => {
      promiseList.push(reprocessInbox(tenantId, inbox.id, clearList, workflow.value));
    });
    await Promise.all(promiseList).then(() => {
      setIsOpen(false);
    });
  };

  return (
    <Modal
      className={s.reprocess_container}
      overlayClassName={s.background}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className={s.header}>
        <h2 className={s.title}>Reprocess Inbox(es) on {tenantId}</h2>
      </div>
      <div className={s.bottom} id={'bottom'}>
        <div className={s.reprocess_inboxes}>
          {inboxes.map((inbox) => (
            <div>{inbox.name}</div>
          ))}
        </div>
        <p className={s.reprocess_desc}>Select the workflow you want to use.</p>
        <Dropdown
          value={workflow}
          onChange={setWorkflow}
          options={[
            { label: 'Main', value: 'main' },
            { label: 'Route', value: 'route' },
            { label: 'Analyze', value: 'analyze' },
            { label: 'Lookup', value: 'lookup' },
            { label: 'Validate', value: 'validate' },
            { label: 'Clean-Up', value: 'cleanup' },
          ]}
        />
        <p className={s.reprocess_desc}>
          Select the fields you want to clear before reprocessing
        </p>
        <div className={s.reprocess_options}>
          <div className={s.row}>
            <span>Select All</span>
            <Checkbox
              indeterminate={
                Object.values(reprocessParams).some((x) => x) && !isAllChecked
              }
              checked={isAllChecked}
              onClick={() => {
                var newState = true;
                if (isAllChecked) {
                  newState = false;
                }
                setReprocessParams({
                  doc_type_id: newState,
                  doc_subtype_id: newState,
                  tag_type_id: newState,
                  entities: newState,
                  'metadata.table': newState,
                });
              }}
            />
          </div>
          <div className={s.row}>
            <span>Doctype ID</span>
            <Checkbox
              checked={reprocessParams.doc_type_id}
              onClick={() =>
                setReprocessParams({
                  ...reprocessParams,
                  doc_type_id: !reprocessParams.doc_type_id,
                })
              }
            />
          </div>
          <div className={s.row}>
            <span>Doc Subtype ID</span>
            <Checkbox
              checked={reprocessParams.doc_subtype_id}
              onClick={() =>
                setReprocessParams({
                  ...reprocessParams,
                  doc_subtype_id: !reprocessParams.doc_subtype_id,
                })
              }
            />
          </div>
          <div className={s.row}>
            <span>Tagtype ID</span>
            <Checkbox
              checked={reprocessParams.tag_type_id}
              onClick={() =>
                setReprocessParams({
                  ...reprocessParams,
                  tag_type_id: !reprocessParams.tag_type_id,
                })
              }
            />
          </div>
          <div className={s.row}>
            <span>Entities</span>
            <Checkbox
              checked={reprocessParams.entities}
              onClick={() =>
                setReprocessParams({
                  ...reprocessParams,
                  entities: !reprocessParams.entities,
                })
              }
            />
          </div>
          <div className={s.row}>
            <span>Table Metadata</span>
            <Checkbox
              checked={reprocessParams['metadata.table']}
              onClick={() =>
                setReprocessParams({
                  ...reprocessParams,
                  'metadata.table': !reprocessParams['metadata.table'],
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <button onClick={() => setIsOpen(false)} className={clsx(s.button, s.button_alt)}>
          Cancel
        </button>
        <button onClick={handleConfirm} className={s.button}>
          {isLoading ? <Ring color={'white'} size={16} /> : 'Reprocess'}
        </button>
      </div>
    </Modal>
  );
};

export default ReprocessModal;
