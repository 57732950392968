import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { getUserToken } from '../shared/helpers';
import { OlympusUser } from '../shared/models';
import { AppThunk, RootState } from './store';

interface UsersState {
  olympusUsers?: OlympusUser[];
}

const initialState: UsersState = {
  olympusUsers: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setOlympusUsers: (state, action: PayloadAction<OlympusUser[]>) => {
      state.olympusUsers = action.payload;
    },
  },
});

const getUsersSelector = (state: RootState): OlympusUser[] => state.users.olympusUsers;

export const getUsersCountSelector = createSelector([getUsersSelector], (users) => {
  if (users) {
    return users.length;
  }
  return null;
});

export const deleteUser = (userId: string) => async (dispatch) => {
  const b = await getUserToken();
  return await axios
    .delete(`${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/users/${userId}`, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    })
    .then((res) => {
      dispatch(getUsers());
    });
};
export const patchUserClaims =
  (userId: string, claims: Map<string, any>) => async (dispatch, getState) => {
    const b = await getUserToken();
    return await axios.patch(
      `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/users/${userId}`,
      { custom_claims: claims },
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      }
    );
  };

export const getUsers = (): AppThunk => async (dispatch, getState) => {
  const b = await getUserToken();
  await axios
    .get(`${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/users?content=true`, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    })
    .then((res) => {
      let users = camelcaseKeys(res.data.results, { deep: true }) as OlympusUser[];
      users = users.filter((e) => e.email != null);
      dispatch(usersSlice.actions.setOlympusUsers(users));
    });
};

export default usersSlice;
