import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Modal from 'react-modal';
import s from './modal.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleConfirm: VoidFunction;
  handleCancel?: VoidFunction;
  title?: string;
  body?: string | ReactElement;
  buttonText?: string;
  confirmType?: 'confirm' | 'delete';
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  title,
  buttonText,
  body,
  handleConfirm,
  handleCancel,
  confirmType,
}) => {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [count]);

  const btnText = useMemo(() => {
    if (confirmType === 'delete') {
      if (count <= 0) {
        return buttonText;
      } else {
        return count;
      }
    }
    return buttonText;
  }, [buttonText, confirmType, count]);

  return (
    <Modal
      className={s.container}
      overlayClassName={s.background}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className={s.content}>
        <div className={s.header}>
          <h2 className={s.title}>{title ?? 'Are you sure?'}</h2>
        </div>
        <div className={s.bottom} id={'bottom'}>
          <p>
            {body ??
              'Deleting a user will prevent any further access to the active environment.'}
          </p>
          <div className={s.buttons}>
            <button
              onClick={() => {
                if (handleCancel) handleCancel();
                setIsOpen(false);
              }}
              className={clsx(s.button, s.button_alt)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              disabled={count > 0 && confirmType === 'delete'}
              className={clsx(s.button, { [s.button__delete]: confirmType === 'delete' })}
            >
              {btnText ?? 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
