import React, { useState } from 'react';
import axios from 'axios';
import { ReactComponent as AdminIcon } from '../../../assets/admin.svg';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as NotifIcon } from '../../../assets/notification.svg';
import { ReactComponent as PaperboxLogo } from '../../../assets/paperbox-logo.svg';
import { ReactComponent as SignOutIcon } from '../../../assets/sign-out.svg';
import { ReactComponent as StatsIcon } from '../../../assets/stats.svg';
import { ReactComponent as TableIcon } from '../../../assets/table.svg';
import { ReactComponent as UsersIcon } from '../../../assets/users.svg';
import ConfirmationModal from '../../../shared/components/modal/ConfirmationModal';
import { getUserToken } from '../../../shared/helpers';
import { useAuth } from '../../../shared/hooks/useAuth';
import { useNotification } from '../../../shared/hooks/useNotification';
import { getPendingDocCountSelector } from '../../../store/documentsSlice';
import { useSelector } from '../../../store/store';
import { getUsersCountSelector } from '../../../store/usersSlice';
import NotificationEdit from '../../notifications/NotificationEdit';
import NavOption from '../nav-option/NavOption';
import s from './sidebar.module.scss';

interface Props {}

const Sidebar: React.FC<Props> = (props) => {
  const { logOut } = useAuth();
  const pendingDocCount = useSelector(getPendingDocCountSelector);
  const usersCount = useSelector(getUsersCountSelector);
  const { setContent, showNotification, setType } = useNotification();
  const [isNotifModalOpen, setIsNotifModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const handleSetNotifications = async () => {
    const b = await getUserToken();

    if (!b) return;
    await axios
      .post(
        `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/notice`,
        {
          message,
          priority,
        },
        {
          headers: { authorization: 'Bearer ' + b },
          params: { tenant_ids: tenants.map((e) => e.value).join(',') },
        }
      )
      .then(() => {
        setContent('Notification set succesfully!');
        showNotification();
        setIsNotifModalOpen(false);
      })
      .catch(() => {
        setContent('Something went wrong');
        setType('error');
        showNotification();
      });
  };
  const [priority, setPriority] = useState(1);
  const [tenants, setTenants] = useState([]);
  const [message, setMessage] = useState('');

  return (
    <aside className={s.sidebar}>
      <div className={s.logo}>
        <PaperboxLogo /> <span>OLYMPUS</span>
      </div>
      <nav className={s.options}>
        <NavOption to={'tenants'} icon={<AdminIcon />} text={'Tenant Management'} />
        <NavOption
          to={'pending'}
          icon={<ClockIcon />}
          text={'Pending Documents'}
          counter={pendingDocCount}
        />
        {user.role === 'superUser' && (
          <NavOption
            to={'users'}
            icon={<UsersIcon />}
            text={'User Management'}
            counter={usersCount}
          />
        )}
        {user.role === 'superUser' && (
          <NavOption to={'masterdata'} icon={<TableIcon />} text={'Masterdata Tables'} />
        )}
        <NavOption to={'stats'} icon={<StatsIcon />} text={'Stats'} />
      </nav>
      {user.role === 'superUser' && (
        <button className={s.notif} onClick={() => setIsNotifModalOpen(true)}>
          <NotifIcon />
        </button>
      )}

      <button className={s.sign_out} onClick={logOut}>
        Sign Out
        <SignOutIcon />
      </button>
      <ConfirmationModal
        isOpen={isNotifModalOpen}
        setIsOpen={setIsNotifModalOpen}
        title={`Set Notification Message`}
        handleConfirm={handleSetNotifications}
        buttonText={'Apply'}
        body={
          <NotificationEdit
            message={message}
            setMessage={setMessage}
            selectedOptions={tenants}
            setSelectedOptions={setTenants}
            priority={priority}
            setPriority={setPriority}
          />
        }
      />
    </aside>
  );
};

export default Sidebar;
