import React, { useEffect } from 'react';
import { FilterOptionType } from '../../../shared/models';
import s from './filters.module.scss';

interface Props {
  option: FilterOptionType;
  selectedOption: FilterOptionType;
  setSelectedOption: React.Dispatch<React.SetStateAction<FilterOptionType>>;
}

const StringFilter: React.FC<Props> = ({ option, setSelectedOption, selectedOption }) => {
  useEffect(() => {
    if (option) {
      if (!option.value && option.options && option.options.length > 0) {
        setSelectedOption((opt) => {
          const clone = { ...opt };
          clone.value = { operator: 'equal to', value1: option.options[0].id };
          return clone;
        });
      }
    }
  }, [option, setSelectedOption]);

  return (
    <div>
      <span className={s.title}>{option.name} is equal to</span>
      <div className={s.option_dropdown_options}></div>

      <div className={s.option_dropdown_dates}>
        <select
          className={s.input}
          name="test"
          id=""
          value={selectedOption?.value?.value1}
          onChange={(e) => {
            setSelectedOption((opt) => {
              const clone = { ...opt };
              clone.value = { operator: 'equal to', value1: e.target.value };
              return clone;
            });
          }}
        >
          {option.options.map((o) => {
            return (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default StringFilter;
