import { useCallback, useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../firebase/firebase-setup';
import authSlice from '../../store/authSlice';
import { useDispatch, useSelector } from '../../store/store';

export const useAuth = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const authError = useSelector((state) => state.auth.authError);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    return signOut(auth).then(() => {
      dispatch(authSlice.actions.setUser(null));
    });
  }, [dispatch]);

  useEffect(() => {
    setIsAuthenticating(true);
  }, []);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await user.getIdTokenResult(true).then((res) => {
          if (res.claims['superUser'] || res.claims['viewer']) {
            const olympusUser = { ...(user as any), role: 'viewer' };
            if (res.claims['superUser']) olympusUser.role = 'superUser';
            dispatch(authSlice.actions.setUser(olympusUser));
            setIsAuthenticating(false);
          } else {
            logOut();
            dispatch(authSlice.actions.setAuthError('Unauthorized'));
            dispatch(authSlice.actions.setUser(null));
            dispatch(authSlice.actions.setIsLoggedIn(false));
            setIsAuthenticating(false);
          }
        });
      } else {
        setIsAuthenticating(false);
        dispatch(authSlice.actions.setUser(null));
        dispatch(authSlice.actions.setIsLoggedIn(false));
      }
    });

    return () => {
      unsub();
    };
  }, [dispatch, logOut]);

  return {
    user,
    authError,
    isAuthenticating,
    logOut,
  };
};
