import { useEffect, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebase-setup';

export const getUserToken = async () => {
  const user = await getAuth(firebaseApp).currentUser;
  return user ? user.getIdToken() : null;
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const operatorMap = {
  'greater than': '>',
  'less than': '<',
  'equal to': '==',
};
export const getCurrentEnvCode = () => {
  let envCode = 'prd';
  switch (process.env.REACT_APP_PAPERBOX_ENVIRONMENT) {
    case 'develop':
      envCode = 'dev';
      break;
    case 'test':
      envCode = 'tst';
      break;
    case 'acceptance':
      envCode = 'acc';
      break;
    case 'production':
      envCode = 'prd';
      break;
  }
  return envCode;
};
