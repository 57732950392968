import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import camelcaseKeys from 'camelcase-keys';
import { collectionGroup, limit, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/firebase-setup';
import { FilterOptionType, MasterDataTable, MasterDataVersion } from '../shared/models';
import { parseFilters } from './documentsSlice';
import { AppThunk } from './store';

interface MasterdataState {
  tables?: MasterDataTable[];
  versions?: MasterDataVersion[];
}

const initialState: MasterdataState = {};

export const masterdataSlice = createSlice({
  name: 'masterdata',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),
    setTables: (state, action: PayloadAction<MasterDataTable[]>) => {
      state.tables = action.payload;
    },
    setVersions: (state, action: PayloadAction<MasterDataVersion[]>) => {
      state.versions = action.payload;
    },
  },
});

export const listenForTables = (): AppThunk => async (dispatch, getState) => {
  const filters = [limit(50)];

  let pendingDocsQuery = query(collectionGroup(db, 'tables'), ...filters);
  onSnapshot(pendingDocsQuery, (data) => {
    const docs: any = data.docs.map((e) => ({ id: e.id, ...e.data() }));
    dispatch(masterdataSlice.actions.setTables(docs));
  });
};

let versionListener;

export const listenForVersions =
  (activeFilters: FilterOptionType[]): AppThunk =>
  async (dispatch, getState) => {
    if (versionListener) versionListener();

    let filters = parseFilters(activeFilters);

    filters = [...filters, orderBy('upload_time', 'desc'), limit(100)];

    let pendingDocsQuery = query(collectionGroup(db, 'versions'), ...filters);
    versionListener = onSnapshot(pendingDocsQuery, (data) => {
      const docs: any = data.docs.map((e) => {
        const tenantId = e.ref.path.split('/')[1];
        return {
          id: e.id,
          ...e.data(),
          tableId: e.ref.parent.parent.id,
          tenantId: tenantId,
          fullDocPath: e.ref.path,
        };
      });
      const mappedDocs = docs.map((doc) => {
        const mapped = Object.entries(doc.mapping).map(([k, v]) => ({
          id: k,
          ...(v as Object),
        }));
        return {
          ...camelcaseKeys(doc),
          ingestionTime: doc['ingestion_time']?.toDate(),
          uploadTime: doc['upload_time']?.toDate(),
          mapping: mapped,
        };
      });
      dispatch(masterdataSlice.actions.setVersions(mappedDocs));
    });
  };

export default masterdataSlice;
