import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector as useStoreSelector } from 'react-redux';
import * as RR from 'react-redux';
import authSlice from './authSlice';
import documentsSlice from './documentsSlice';
import masterdataSlice from './masterdataSlice';
import { modelsSlice } from './modelsSlice';
import { notificationSlice } from './notificationSlice';
import { statsSlice } from './statsSlice';
import usersSlice from './usersSlice';

export const appReducer = combineReducers({
  documents: documentsSlice.reducer,
  auth: authSlice.reducer,
  notification: notificationSlice.reducer,
  users: usersSlice.reducer,
  models: modelsSlice.reducer,
  masterdata: masterdataSlice.reducer,
  stats: statsSlice.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action);
  return appReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  devTools: true,
});

export type RootState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useSelector: TypedUseSelectorHook<RootState> = useStoreSelector;
export const useDispatch = () => RR.useDispatch<AppDispatch>();
