import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { ReactComponent as SortDownIcon } from '../../assets/sort-down.svg';
import { ReactComponent as SortUpIcon } from '../../assets/sort-up.svg';
import { UsersSorting, UsersSortingProp } from '../../shared/models';
import { useSelector } from '../../store/store';
import m from '../../styles/style/_shared.module.scss';
import s from './users.module.scss';
import UsersRow from './UsersRow';

interface Props {}

const Users: React.FC<Props> = (props) => {
  const users = useSelector((state) => state.users.olympusUsers);
  const [sorting, setSorting] = useState<UsersSorting>({
    prop: 'email',
    descending: true,
  });

  const listRef = useRef();

  const handleSetSorting = (prop: UsersSortingProp) => {
    if (sorting.prop === prop) {
      setSorting({ ...sorting, descending: !sorting.descending });
    } else {
      setSorting({ prop, descending: false });
    }
    if (listRef.current) {
      const list = listRef.current as HTMLDivElement;
      list.scrollTo({ top: 0 });
    }
  };

  const sortedUserList = useMemo(() => {
    if (users) {
      return [...users].sort((a, b) => {
        let sortingValue;
        switch (sorting.prop) {
          case 'customClaims': {
            const brB = b.customClaims ? b.customClaims['superUser'] : '?';
            const brA = a.customClaims ? a.customClaims['superUser'] : '?';
            sortingValue = brB.localeCompare(brA);
            break;
          }
          case 'displayName': {
            sortingValue = b.displayName.localeCompare(a.displayName);
            break;
          }
          case 'email': {
            sortingValue = b.email.localeCompare(a.email);
            break;
          }

          default:
            sortingValue = 0;
            break;
        }
        if (sorting.descending) sortingValue = sortingValue * -1;

        return sortingValue;
      });
    } else {
      return [];
    }
  }, [users, sorting]);

  const RowWrapper = ({ index, style }) => {
    const user = sortedUserList[index];
    return (
      <div
        style={{
          ...style,
          paddingBottom: 4,
          paddingTop: 4,
          width: '100%',
        }}
      >
        <UsersRow user={user} key={user.uid} />
      </div>
    );
  };

  return (
    <main className={s.container}>
      <h1 className={m.page_title}>User Management</h1>
      <div className={s.table}>
        <div className={s.header}>
          <div className={s.first} />
          <button
            className={clsx(s.header_name, s.second)}
            onClick={() => handleSetSorting('displayName')}
          >
            <span>Name</span>
            {sorting.prop === 'displayName' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(s.header_name, s.third)}
            onClick={() => handleSetSorting('email')}
          >
            <span>Email</span>
            {sorting.prop === 'email' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>

          <div className={s.fourth}>Edit Access</div>
          <div className={s.fifth}>Viewer Access</div>
          <div className={s.sixth}>Actions</div>
        </div>
        <div className={s.body}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                outerRef={listRef}
                itemSize={53}
                height={height}
                itemCount={users.length}
                width={width}
              >
                {RowWrapper}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    </main>
  );
};

export default Users;
