import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ClipboardIcon } from '../../assets/clipboard.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import ConfirmationModal from '../../shared/components/modal/ConfirmationModal';
import Toggle from '../../shared/components/toggle/Toggle';
import { useNotification } from '../../shared/hooks/useNotification';
import { OlympusUser } from '../../shared/models';
import { useDispatch } from '../../store/store';
import { deleteUser, patchUserClaims } from '../../store/usersSlice';
import s from './users.module.scss';

interface Props {
  user: OlympusUser;
}

const UsersRow: React.FC<Props> = ({ user }) => {
  const [isSuperuser, setIsSuperuser] = useState(
    user?.customClaims ? user?.customClaims['superUser'] : false
  );
  const [isViewer, setIsViewer] = useState(
    user?.customClaims
      ? user?.customClaims['viewer'] || user?.customClaims['superUser']
      : false
  );
  const { setContent, showNotification } = useNotification();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const dispatch = useDispatch();

  const handleToggleSuperuser = () => {
    setIsSuperuser(!isSuperuser);
    const claims = { ...user.customClaims, superUser: !isSuperuser };
    dispatch(patchUserClaims(user.uid, claims))
      .then(() => {
        setContent(`User updated successfully`);
        showNotification();
      })
      .catch(() => {
        setIsSuperuser(!isSuperuser);
      });
  };

  const handleToggleViewer = () => {
    setIsViewer(!isViewer);
    const claims = { ...user.customClaims, viewer: !isViewer };
    dispatch(patchUserClaims(user.uid, claims))
      .then(() => {
        setContent(`User updated successfully`);
        showNotification();
      })
      .catch(() => {
        setIsViewer(!isViewer);
      });
  };

  const handleDelete = () => {
    dispatch(deleteUser(user.uid))
      .then(() => {
        setContent(`${user.email} deleted successfully`);

        setDeleteModalActive(false);
        showNotification();
      })
      .catch((err) => {
        console.log(err);
        setDeleteModalActive(false);
      });
  };

  const handleClipboard = () => {
    navigator.clipboard.writeText(user.uid);
    setContent(`Copied user ID to clipboard`);
    showNotification();
  };
  return (
    <div className={s.user_item}>
      <ConfirmationModal
        isOpen={deleteModalActive}
        setIsOpen={setDeleteModalActive}
        title={`Delete ${user.email}`}
        handleConfirm={handleDelete}
        buttonText={'Delete'}
        confirmType={'delete'}
        body={'Deleting a user will prevent any further access to this environment.'}
      />
      <div className={s.first}>
        <img
          referrerPolicy={'no-referrer'}
          className={s.user_item_avatar}
          src={user.photoUrl}
          alt="Avatar"
        />
      </div>
      <div className={clsx(s.second, s.user_item_name)}>
        <p>{user?.displayName}</p>
      </div>
      <div className={s.third}>
        <span className={s.user_item_email}>{user.email}</span>
      </div>
      <div className={s.fourth}>
        <div className={s.user_item_claims}>
          <Toggle setState={handleToggleSuperuser} state={isSuperuser} />
        </div>
      </div>
      <div className={s.fifth}>
        <div className={s.user_item_claims}>
          <Toggle disabled={isSuperuser} setState={handleToggleViewer} state={isViewer} />
        </div>
      </div>
      <div className={s.sixth}>
        <div className={s.user_item_actions}>
          <button onClick={handleClipboard} className={s.user_item_action}>
            <ClipboardIcon />
          </button>

          <button
            onClick={() => setDeleteModalActive(true)}
            className={s.user_item_action}
          >
            <DeleteIcon style={{ transform: 'scale(0.7)' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersRow;
