import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import { FilterOptionType } from '../../../shared/models';
import FilterDropdown from './FilterDropdown';
import s from './filters.module.scss';
interface Props {
  activeFilters: FilterOptionType[];
  setActiveFilters: React.Dispatch<React.SetStateAction<FilterOptionType[]>>;
  filterList: FilterOptionType[];
}

const FiltersNew: React.FC<Props> = ({ activeFilters, filterList, setActiveFilters }) => {
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [selectedOption, setSelectedOption] = useState<FilterOptionType>(null);
  const ref = useDetectClickOutside({
    onTriggered: (e) => {
      if (e.target['id'].includes('react-select')) return;
      setIsCreatingNew(false);
      setSelectedOption(null);
    },
  });

  const availableFilters = useMemo(() => {
    if (filterList) {
      return filterList.filter((fi) =>
        activeFilters.length > 0
          ? activeFilters.findIndex((ac) => ac.id === fi.id) === -1
          : true
      );
    }
  }, [activeFilters, filterList]);

  return (
    <div ref={ref} className={s.new_wrapper}>
      {availableFilters.length > 0 && (
        <button
          className={clsx(s.option, s.option__basic)}
          onClick={() => setIsCreatingNew(!isCreatingNew)}
        >
          Add Filter <PlusIcon />
        </button>
      )}

      {isCreatingNew && (
        <div className={s.new_inner}>
          <div className={s.new}>
            {availableFilters.map((li) => {
              return (
                <div
                  key={li.id}
                  onClick={() => {
                    setSelectedOption(li);
                  }}
                  className={clsx(s.new_option, {
                    [s.new_option__active]: selectedOption?.name === li.name,
                  })}
                >
                  {li.name}
                </div>
              );
            })}
          </div>
          {selectedOption && (
            <FilterDropdown
              handleConfirm={(newFilter) => {
                setIsCreatingNew(false);
                setActiveFilters((af) => {
                  return [...af, newFilter];
                });
              }}
              handleCancel={() => setIsCreatingNew(false)}
              filter={selectedOption}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FiltersNew;
