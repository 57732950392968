import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import RadioButton from '../../../shared/components/radio-button/RadioButton';
import { FilterOptionType } from '../../../shared/models';
import s from './filters.module.scss';

interface Props {
  option: FilterOptionType;
  selectedOption: FilterOptionType;
  setSelectedOption: React.Dispatch<React.SetStateAction<FilterOptionType>>;
}

const DateFilter: React.FC<Props> = ({ option, setSelectedOption, selectedOption }) => {
  useEffect(() => {
    if (option) {
      if (!option.value) {
        setSelectedOption((option) => {
          const copy = cloneDeep(option);
          copy.value = { operator: 'equal to' };
          return copy;
        });
      }
    }
  }, [option, setSelectedOption]);

  return (
    <div>
      <span className={s.title}>{option.name} is</span>
      <div className={s.option_dropdown_options}>
        <div className={s.option_dropdown_option}>
          <RadioButton
            id={'greater than'}
            checked={selectedOption.value?.operator === 'greater than'}
            onClick={() =>
              setSelectedOption((option) => {
                const copy = cloneDeep(option);
                copy.value.operator = 'greater than';
                return copy;
              })
            }
          />
          <label htmlFor="greater than"> greater than</label>
        </div>
        <div className={s.option_dropdown_option}>
          <RadioButton
            id={'less than'}
            checked={selectedOption.value?.operator === 'less than'}
            onClick={() =>
              setSelectedOption((option) => {
                const copy = cloneDeep(option);
                copy.value.operator = 'less than';
                return copy;
              })
            }
          />
          <label htmlFor="less than">less than</label>
        </div>
        <div className={s.option_dropdown_option}>
          <RadioButton
            id={'between'}
            checked={selectedOption.value?.operator === 'between'}
            onClick={() =>
              setSelectedOption((option) => {
                const copy = cloneDeep(option);
                copy.value.operator = 'between';
                return copy;
              })
            }
          />
          <label htmlFor="between">between</label>
        </div>
      </div>
      <div className={s.option_dropdown_dates}>
        <input
          value={selectedOption?.value?.value1}
          onChange={(e) =>
            setSelectedOption((option) => {
              const copy = cloneDeep(option);
              copy.value.value1 = e.target.value;
              return copy;
            })
          }
          className={s.input}
          type="date"
        />
        {selectedOption.value?.operator === 'between' && (
          <input
            value={selectedOption?.value?.value2}
            onChange={(e) =>
              setSelectedOption((option) => {
                const copy = cloneDeep(option);

                copy.value.value2 = e.target.value;
                return copy;
              })
            }
            className={s.input}
            type="date"
          />
        )}
      </div>
    </div>
  );
};

export default DateFilter;
