import React from 'react';
import clsx from 'clsx';
import { ReactComponent as ClipboardIcon } from '../../assets/clipboard.svg';
import { ReactComponent as CloudIcon } from '../../assets/cloud.svg';
import { ReactComponent as FirebaseIcon } from '../../assets/firebase.svg';
import { ReactComponent as GlobeIcon } from '../../assets/globe.svg';
import { getCurrentEnvCode } from '../../shared/helpers';
import { useNotification } from '../../shared/hooks/useNotification';
import { StatsTenant } from '../../shared/models';
import { OlympusAdminUser } from '../../store/authSlice';
import { useSelector } from '../../store/store';
import p from '../pending-documents/pending-documents.module.scss';
import s from './stats.module.scss';
interface Props {
  statsTenant: StatsTenant;
  user: OlympusAdminUser;
}

const StatsRow: React.FC<Props> = ({ statsTenant, user }) => {
  const { setContent, showNotification } = useNotification();

  const stats = useSelector((state) => state.stats.stats);
  const handleCloud = () => {
    const proj = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    let url = `https://console.cloud.google.com/customer-identity;tenantId=${statsTenant.id}/providers?project=${proj}`;
    window.open(url, '_blank').focus();
  };
  const handleFirebase = () => {
    const proj = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    let url = `https://console.firebase.google.com/project/${proj}/firestore/data/tenants/${statsTenant.id}`;
    window.open(url, '_blank').focus();
  };
  const handleDomain = () => {
    const envCode = getCurrentEnvCode();
    let url = `https://${statsTenant.domain}.paperbox.ai`;
    if (envCode !== 'prd') {
      url = `https://${statsTenant.domain}.${getCurrentEnvCode()}.paperbox.ai`;
    }
    window.open(url, '_blank').focus();
  };
  const handleClipboard = () => {
    navigator.clipboard.writeText(statsTenant.id);
    setContent(`Copied tenant ID to clipboard`);
    showNotification();
  };
  return (
    <>
      <div className={p.document_item}>
        <div className={clsx(s.zero, s.flex_col)}>
          {statsTenant.inflow > 0 && <div className={clsx(s.status)} />}
        </div>
        <div
          style={{ justifyContent: 'flex-start' }}
          className={clsx(s.first, s.flex_col)}
        >
          <button
            className={clsx(p.document_item_tenant_name, p.document_item_row_item)}
            onClick={handleClipboard}
          >
            <span style={{ textAlign: 'start' }}>{statsTenant.name}</span>

            <div className={p.hover_icon}>
              <ClipboardIcon />
            </div>
          </button>
        </div>
        <div className={clsx(s.second, s.flex_col)}>
          <div className={s.percentage_wrapper}>
            <div
              className={s.percentage_inner}
              style={{ width: `${(statsTenant.inflow / stats.inflow) * 100 || 0}%` }}
            ></div>
          </div>
          <span>{statsTenant.inflow}</span>
        </div>
        <div className={clsx(s.third, s.flex_col)}>
          <div className={s.percentage_wrapper}>
            <div
              className={s.percentage_inner}
              style={{ width: `${statsTenant.automationPercent}%` }}
            ></div>
          </div>
          <span>{statsTenant.automationPercent}%</span>
        </div>
        <div className={clsx(s.fourth, s.flex_col)}>
          <div className={s.percentage_wrapper}>
            <div
              className={s.percentage_inner}
              style={{
                width: `${(statsTenant.activeUsers / stats.activeUsers) * 100 || 0}%`,
              }}
            ></div>
          </div>
          <span>{statsTenant.activeUsers}</span>
        </div>
        <div className={clsx(s.fifth, s.flex_col)}>
          <div className={s.percentage_wrapper}>
            <div
              className={s.percentage_inner}
              style={{ width: `${(statsTenant.open / stats.open) * 100 || 0}%` }}
            ></div>
          </div>
          <span>{statsTenant.open}</span>
        </div>
        <div className={clsx(s.sixth)}>
          <div className={p.document_item_actions}>
            <button
              onClick={handleDomain}
              data-tip
              data-for={`stats-env`}
              className={p.document_item_action}
            >
              <GlobeIcon />
            </button>

            {user.role === 'superUser' && (
              <>
                <button
                  onClick={handleFirebase}
                  data-tip
                  data-for={`stats-firestore`}
                  className={p.document_item_action}
                >
                  <FirebaseIcon />
                </button>
                <button
                  onClick={handleCloud}
                  data-tip
                  data-for={`stats-cloud`}
                  className={clsx(p.document_item_action)}
                >
                  <CloudIcon />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsRow;
