import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { auth } from '../firebase/firebase-setup';

const provider = new GoogleAuthProvider();

export interface OlympusAdminUser extends User {
  role: 'viewer' | 'superUser';
}

interface AuthState {
  user?: OlympusAdminUser;
  isLoggedIn?: boolean;
  authError?: string;
}

const initialState: AuthState = {
  isLoggedIn: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),
    setUser: (state, action: PayloadAction<OlympusAdminUser>) => {
      state.user = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setAuthError: (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
    },
  },
});

export const signInWithGoogle = () => async (dispatch, getState) => {
  return signInWithPopup(auth, provider);
};

export default authSlice;
