import React, { useEffect, useMemo, useState } from 'react';
import Dropdown from '../../shared/components/dropdown/Dropdown';
import Slider from '../../shared/components/slider/Slider';
import { useSelector } from '../../store/store';
import s from './notification-edit.module.scss';
interface Props {
  selectedOptions: any[];
  setSelectedOptions: (opt) => void;
  message: string;
  setMessage: (opt) => void;
  priority: number;
  setPriority: (opt) => void;
}

const NotificationEdit: React.FC<Props> = ({
  message,
  setMessage,
  setSelectedOptions,
  selectedOptions,
  setPriority,
  priority,
}) => {
  const structure = useSelector((state) => state.documents.structure);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (structure?.tenants) {
      const mapped = structure.tenants.map((t) => {
        return { value: t.id, label: t.id };
      });
      setOptions(mapped);
    }
  }, [structure]);

  const priorityColor = useMemo(() => {
    if (priority === 0) {
      return '#91C500FF';
    } else if (priority === 1) {
      return '#ffca2a';
    } else {
      return '#ff2a2a';
    }
  }, [priority]);

  const priorityText = useMemo(() => {
    if (priority === 0) {
      return 'Low';
    } else if (priority === 1) {
      return 'Medium';
    } else {
      return 'High';
    }
  }, [priority]);

  return (
    <div className={s.wrapper}>
      <label htmlFor="input">Message</label>
      <input
        placeholder={'Enter a message here'}
        id={'input'}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className={s.input}
        type="text"
      />

      <label htmlFor="dropdown">Tenants</label>
      <Dropdown
        id={'dropdown'}
        isMulti
        options={options}
        value={selectedOptions}
        onChange={(selected: any) => {
          selected.length && selected.find((option) => option.value === 'all')
            ? setSelectedOptions(options)
            : setSelectedOptions(selected);
        }}
      />
      <label>Priority ({priorityText})</label>
      <div className={s.slider}>
        <Slider
          value={priority}
          max={2}
          min={0}
          onChange={setPriority}
          color={priorityColor}
        />
      </div>
    </div>
  );
};

export default NotificationEdit;
