import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import ReprocessModal from '../../shared/components/modal/ReprocessModal';
import { Stats, Tenant } from '../../shared/models';
import { useSelector } from '../../store/store';
import st from '../stats/stats.module.scss';
import InboxRow from './InboxRow';
import s from './tenant-management.module.scss';

interface Props {
  tenant: Tenant;
  stats: Stats;
}

const TenantGroup: React.FC<Props> = ({ tenant, stats }) => {
  const [isReprocessModalOpen, setIsReprocessModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const tenantStats = useMemo(() => {
    if (stats) {
      return stats.tenants.find((e) => e.id === tenant.id);
    }
  }, [stats, tenant]);
  const tenantOpen = tenantStats?.open || 0;

  const totalOpen = stats?.open;

  return (
    <>
      {isReprocessModalOpen && (
        <ReprocessModal
          tenantId={tenant.id}
          inboxes={tenant.inboxes}
          isOpen={isReprocessModalOpen}
          setIsOpen={setIsReprocessModalOpen}
        />
      )}
      <div className={s.tenant_rows}>
        <div className={s.tenant_row}>
          <div className={clsx(s.first)}>
            <b>{tenant.id}</b>
          </div>
          <div className={s.second}>
            <div className={st.percentage_wrapper}>
              <div
                className={st.percentage_inner}
                style={{
                  width: `${(tenantOpen / totalOpen) * 100 || 0}%`,
                }}
              ></div>
            </div>
            {tenantOpen ?? 0}
          </div>
          <div className={clsx(s.third)}>
            {user.role === 'superUser' && (
              <button onClick={() => setIsReprocessModalOpen(true)}>
                <ReloadIcon />
              </button>
            )}
          </div>
        </div>

        {tenant.inboxes.map((inbox) => {
          var inboxStats = tenantStats?.openInboxes[inbox.id];
          return (
            <InboxRow
              tenantOpenDocs={tenantOpen ?? 0}
              key={inbox.id}
              inbox={inbox}
              tenantId={tenant.id}
              openDocs={inboxStats}
            />
          );
        })}
      </div>
    </>
  );
};

export default TenantGroup;
