import { useEffect } from 'react';
import { listenForTables, listenForVersions } from '../../store/masterdataSlice';
import { useDispatch, useSelector } from '../../store/store';
import { FilterOptionType } from '../models';

export const useMasterDataTables = (canFetch: boolean, filters: FilterOptionType[]) => {
  const tables = useSelector((state) => state.masterdata.tables);
  const versions = useSelector((state) => state.masterdata.versions);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && filters) {
      dispatch(listenForTables());
      dispatch(listenForVersions(filters));
    }
  }, [filters, user, dispatch]);

  return {
    tables,
    versions,
  };
};
