import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { ReactComponent as BounceIcon } from '../../assets/bounce.svg';
import { ReactComponent as ClipboardIcon } from '../../assets/clipboard.svg';
import { ReactComponent as CloudIcon } from '../../assets/cloud.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as FirebaseIcon } from '../../assets/firebase.svg';
import { ReactComponent as IDIcon } from '../../assets/id.svg';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import Checkbox from '../../shared/components/checkbox/Checkbox';
import ConfirmationModal from '../../shared/components/modal/ConfirmationModal';
import { useNotification } from '../../shared/hooks/useNotification';
import { Document, InboxInfo } from '../../shared/models';
import { OlympusAdminUser } from '../../store/authSlice';
import {
  documentsSlice,
  patchDocument,
  postWorkflowRuns,
} from '../../store/documentsSlice';
import { useDispatch, useSelector } from '../../store/store';
import s from './pending-documents.module.scss';

interface Props {
  tenantId: string;
  inbox: InboxInfo;
  user: OlympusAdminUser;
  document: Document;
}

const PendingDocumentRow: React.FC<Props> = ({ document, inbox, tenantId, user }) => {
  const { setContent, showNotification, setType } = useNotification();
  const documentList = useSelector((state) => state.documents.documentList);
  const checkedDocIds = useSelector((state) => state.documents.checkedDocIds);
  const [isBounceModalActive, setIsBounceModalActive] = useState(false);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [isReprocessModalActive, setIsReprocessModalActive] = useState(false);

  const dispatch = useDispatch();

  const isChecked = useMemo(() => {
    return checkedDocIds.includes(document.id);
  }, [checkedDocIds, document]);

  const handleCheck = () => {
    var newChecked = [];
    if (isChecked) {
      newChecked = checkedDocIds.filter((e) => e !== document.id);
    } else {
      newChecked = [...checkedDocIds, document.id];
    }

    dispatch(documentsSlice.actions.setCheckedDocIds(newChecked));
  };

  const handleReprocess = () => {
    const existingDocs = cloneDeep(documentList);
    const existingIndex = existingDocs.findIndex((e) => e.id === document.id);
    existingDocs[existingIndex] = { ...document, reprocessTriggered: true };
    dispatch(documentsSlice.actions.setDocumentList(existingDocs));
    setIsReprocessModalActive(false);
    dispatch(
      postWorkflowRuns(
        document.id,
        tenantId,
        document.inbox?.id ?? null,
        document.routerId ?? null,
        document.isMutation ? document.documentId : null
      )
    )
      .then((res) => {
        if (res.status === 204) {
          setContent('Document reprocessing started.');
          showNotification();
        }
      })
      .catch(() => {
        setType('error');
        setContent('Document reprocess trigger failed');
        showNotification();
      });
  };

  const handleBounce = () => {
    setIsBounceModalActive(false);
    let existingDocs = cloneDeep(documentList);
    const existingIndex = existingDocs.findIndex((e) => e.id === document.id);
    existingDocs[existingIndex] = { ...document, isHidden: true };
    dispatch(documentsSlice.actions.setDocumentList(existingDocs));
    dispatch(
      patchDocument(
        { action: { type: 'bounce' } },
        document.id,
        tenantId,
        document.inbox?.id ?? null,
        document.routerId ?? null,
        document.isMutation ? document.documentId : null
      )
    )
      .then((res) => {
        if (res.status === 204) {
          setContent('Document bounced successfully');
          showNotification();
        }
      })
      .catch(() => {
        existingDocs = [...existingDocs];
        existingDocs[existingIndex] = { ...document, isHidden: false, isChecked: false };
        dispatch(documentsSlice.actions.setDocumentList(existingDocs));
        setType('error');
        setContent('Document bounce failed');
        showNotification();
      });
  };

  const handleDelete = () => {
    setIsDeleteModalActive(false);
    dispatch(
      postWorkflowRuns(
        document.id,
        tenantId,
        document.inbox?.id ?? null,
        document.routerId ?? null,
        document.isMutation ? document.documentId : null,
        true
      )
    )
      .then((res) => {
        if (res.status === 204) {
          setContent('Document Delete triggered.');
          showNotification();
        }
      })
      .catch(() => {
        setType('error');
        setContent('Document delete trigger failed');
        showNotification();
      });
  };

  const handleDocClipboard = () => {
    if (document.isMutation) {
      navigator.clipboard.writeText(`${document.documentId}/mutations/${document.id}`);
    } else {
      navigator.clipboard.writeText(document.id);
    }
    setContent(`Copied document ID to clipboard`);
    showNotification();
  };
  const handleTenantClipboard = () => {
    navigator.clipboard.writeText(tenantId);
    setContent(`Copied tenant ID to clipboard`);
    showNotification();
  };
  const handleProviderIdClipboard = () => {
    navigator.clipboard.writeText(document?.providerDocumentId);
    setContent(`Copied provider ID to clipboard`);
    showNotification();
  };
  const handleInboxClipboard = () => {
    if (inbox) {
      navigator.clipboard.writeText(inbox.id);
      setContent(`Copied inbox ID to clipboard`);
    } else {
      navigator.clipboard.writeText(document.routerId);
      setContent(`Copied router ID to clipboard`);
    }

    showNotification();
  };
  const handleCloud = () => {
    const proj = process.env.REACT_APP_FIREBASE_PROJECT_ID;

    const url = `https://console.cloud.google.com/storage/browser/${document.gcsLocation.replace(
      'gs://',
      ''
    )}?authuser=0&project=${proj}`;
    if (document.gcsLocation) window.open(url, '_blank').focus();
  };
  const handleFirebase = () => {
    const proj = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    let url;

    if (document.isMutation) {
      url = `https://console.firebase.google.com/project/${proj}/firestore/data/tenants/${tenantId}/documents/${document.documentId}/mutations/${document.id}`;
    } else {
      url = `https://console.firebase.google.com/project/${proj}/firestore/data/tenants/${tenantId}/documents/${document.id}`;
    }

    window.open(url, '_blank').focus();
  };

  const formattedDate = useMemo(() => {
    if (document.uploadTime) {
      const d = document.uploadTime;
      const date = d.toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
      const time = d.toLocaleTimeString('be-NL', {
        hour: '2-digit',
        minute: '2-digit',
      });

      return `${date} ${time}`;
    }
  }, [document]);

  const formattedStepName = useMemo(() => {
    if (document.breadcrumbs) {
      let name = document.breadcrumbs[document.breadcrumbs.length - 1]?.name;
      if (name) {
        name = name.replace(/([A-Z])/g, ' $1').trim();
        return name;
      } else {
        return '?';
      }
    } else {
      return '?';
    }
  }, [document]);

  const stepAttempts = useMemo(() => {
    if (document.latestWorkflowRun && document.breadcrumbs) {
      if (document.latestWorkflowRun.attempt === 1) return null;
      return document.latestWorkflowRun.attempt;
    } else {
      return null;
    }
  }, [document]);

  if (document?.isHidden) return null;

  return (
    <>
      {isReprocessModalActive && (
        <ConfirmationModal
          isOpen={isReprocessModalActive}
          setIsOpen={setIsReprocessModalActive}
          title={`Reprocess document`}
          handleConfirm={handleReprocess}
          buttonText={'Reprocess'}
          body={'This action will reprocess the selected document.'}
        />
      )}
      {isDeleteModalActive && (
        <ConfirmationModal
          isOpen={isDeleteModalActive}
          setIsOpen={setIsDeleteModalActive}
          title={`Delete document`}
          handleConfirm={handleDelete}
          handleCancel={() => {
            const existingDocs = cloneDeep(documentList);
            const existingIndex = existingDocs.findIndex((e) => e.id === document.id);
            existingDocs[existingIndex] = { ...document, pendingDelete: false };
            dispatch(documentsSlice.actions.setDocumentList(existingDocs));
          }}
          buttonText={'DELETE'}
          confirmType={'delete'}
          body={
            'Warning: This action is irreversible. Deleting documents is permanent and cannot be undone. Please ensure you understand the consequences before proceeding.'
          }
        />
      )}

      {isBounceModalActive && (
        <ConfirmationModal
          isOpen={isBounceModalActive}
          setIsOpen={setIsBounceModalActive}
          title={`Bounce document`}
          handleConfirm={handleBounce}
          buttonText={'Bounce'}
          body={
            'This action will trigger a bounce back to the original sender. This action can not be undone.'
          }
        />
      )}

      <div
        className={clsx(s.document_item, {
          [s.document_item__delete]: document?.pendingDelete,
        })}
      >
        {user.role === 'superUser' && (
          <div className={clsx(s.first)}>
            <Checkbox checked={isChecked} onClick={handleCheck} />
          </div>
        )}
        <div
          style={{ justifyContent: 'flex-start' }}
          className={clsx(s.second, s.flex_col)}
        >
          <button
            onClick={handleProviderIdClipboard}
            className={clsx(s.document_item_row_item, s.document_item_name, {
              [s.document_item_name__id]: !document?.name,
            })}
          >
            <span
              style={{
                textAlign: 'start',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {document?.name ? document.name.slice(0, 50) : document.documentId}
            </span>
            {document.isMutation && <div className={s.token}>M</div>}

            <div className={s.hover_icon}>
              <ClipboardIcon />
            </div>
          </button>
        </div>
        <div className={clsx(s.third, s.flex_col)}>
          <button
            className={clsx(s.document_item_tenant_name, s.document_item_row_item)}
            onClick={handleTenantClipboard}
          >
            <span>{tenantId}</span>
            <div className={s.hover_icon}>
              <ClipboardIcon />
            </div>
          </button>
        </div>
        <div className={clsx(s.fourth, s.flex_col)}>
          <button
            className={clsx(s.document_item_inbox_name, s.document_item_row_item)}
            onClick={handleInboxClipboard}
          >
            <span>{inbox?.name || document.routerId}</span>

            {document.routerId && <div className={s.token}>R</div>}
            <div className={s.hover_icon}>
              <ClipboardIcon />
            </div>
          </button>
        </div>
        <div className={clsx(s.fifth)}>
          <span className={s.document_item_upload}>{formattedDate}</span>
        </div>

        <div className={clsx(s.sixth)}>
          <div className={s.document_item_status}>
            <div
              className={s.document_item_status_tag}
              data-tip={document.id + document.tenantId + document.documentId}
              data-for="custom-tooltip-radius"
            >
              <div className={s.document_item_status_tag__main}>{formattedStepName}</div>
              {stepAttempts && (
                <div className={s.document_item_status_tag__sub}>{stepAttempts}</div>
              )}
            </div>
          </div>
        </div>
        {user.role === 'superUser' && (
          <div className={clsx(s.seventh)}>
            <div className={s.document_item_actions}>
              <button
                data-tip
                data-for={`delete`}
                onClick={() => {
                  const existingDocs = cloneDeep(documentList);
                  const existingIndex = existingDocs.findIndex(
                    (e) => e.id === document.id
                  );
                  existingDocs[existingIndex] = { ...document, pendingDelete: true };
                  dispatch(documentsSlice.actions.setDocumentList(existingDocs));
                  setIsDeleteModalActive(true);
                }}
                className={clsx(s.document_item_action, s.document_item_action__delete)}
              >
                <DeleteIcon style={{ transform: 'scale(0.7)' }} />
              </button>
              <button
                data-tip
                data-for={`copy`}
                onClick={handleDocClipboard}
                className={s.document_item_action}
              >
                <IDIcon />
              </button>
              <button
                data-tip
                data-for={`bounce`}
                onClick={() => setIsBounceModalActive(true)}
                className={clsx(s.document_item_action, {
                  [s.document_item_action__disabled]: document.action,
                })}
              >
                <BounceIcon />
              </button>
              <button
                data-tip
                data-for={`reprocess`}
                onClick={() => setIsReprocessModalActive(true)}
                className={clsx(s.document_item_action, {
                  [s.document_item_action__pending]: document.reprocessTriggered,
                })}
              >
                <ReloadIcon />
              </button>

              <button
                data-tip
                data-for={`cloud`}
                onClick={handleCloud}
                className={s.document_item_action}
              >
                <CloudIcon />
              </button>
              <button
                data-tip
                data-for={`firestore`}
                onClick={handleFirebase}
                className={clsx(s.document_item_action)}
              >
                <FirebaseIcon />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PendingDocumentRow;
