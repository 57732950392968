import { useCallback } from 'react';
import { notificationSlice } from '../../store/notificationSlice';
import { useDispatch, useSelector } from '../../store/store';
import { sleep } from '../helpers';
import { NotificationType } from '../models';

export const useNotification = () => {
  const isActive = useSelector((state) => state.notification.isActive);
  const content = useSelector((state) => state.notification.notificationContent);
  const notificationType = useSelector((state) => state.notification.notificationType);
  const dispatch = useDispatch();

  const setNotificationContent = useCallback(
    (content: string) => {
      dispatch(notificationSlice.actions.setNotificationContent(content));
    },
    [dispatch]
  );
  const setNotificationType = useCallback(
    (type: NotificationType) => {
      dispatch(notificationSlice.actions.setNotificationType(type));
    },
    [dispatch]
  );

  const showNotification = useCallback(() => {
    dispatch(notificationSlice.actions.setIsActive(true));
    setTimeout(() => {
      dispatch(notificationSlice.actions.setIsActive(false));
      sleep(300).then(() =>
        dispatch(notificationSlice.actions.setNotificationType('info'))
      );
    }, 3000);
  }, [dispatch]);

  return {
    showNotification,
    isActive,
    notificationType,
    setType: setNotificationType,
    setContent: setNotificationContent,
    content,
  };
};
