import React, { cloneElement, ReactComponentElement } from 'react';
import clsx from 'clsx';
import { NavLink, To } from 'react-router-dom';
import s from './nav-option.module.scss';

interface Props {
  icon?: ReactComponentElement<any>;
  text: string;
  to: To;
  counter?: number;
}

const NavOption: React.FC<Props> = ({ icon, text, to, counter }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => clsx(s.option, { [s.option__active]: isActive })}
    >
      {icon && cloneElement(icon, { className: clsx(s.option_icon) })}
      <span className={s.option_title}>{text}</span>
      {counter != null && (
        <div className={s.counter}>{counter > 99 ? '99+' : counter}</div>
      )}
    </NavLink>
  );
};

export default NavOption;
