import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as SortDownIcon } from '../../assets/sort-down.svg';
import { ReactComponent as SortUpIcon } from '../../assets/sort-up.svg';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import { usePrevious } from '../../shared/helpers';
import { useMasterDataTables } from '../../shared/hooks/useMasterDataTables';
import {
  FilterOptionType,
  MasterdataSorting,
  MasterdataSortingProp,
} from '../../shared/models';
import m from '../../styles/style/_shared.module.scss';
import Filters from '../core/filters/Filters';
import VersionRow from './VersionRow';
import s from './versions.module.scss';

interface Props {}

const Versions: React.FC<Props> = (props) => {
  const [sorting, setSorting] = useState<MasterdataSorting>({
    prop: 'status',
    descending: false,
  });
  const [activeFilters, setActiveFilters] = useState<FilterOptionType[]>([
    {
      type: 'date',
      id: 'uploadTime',
      name: 'Upload Date',
      value: {
        value1: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split('T')[0],
        operator: 'greater than',
      },
    },
  ]);

  const handleSetSorting = (prop: MasterdataSortingProp) => {
    if (sorting.prop === prop) {
      setSorting({ ...sorting, descending: !sorting.descending });
    } else {
      setSorting({ prop, descending: false });
    }
  };
  const { versions, tables } = useMasterDataTables(true, activeFilters);

  const tableName = useCallback(
    (tableId: string) => {
      var table = tables.find((tab) => tab.id === tableId);
      return table?.name ?? '';
    },
    [tables]
  );

  const sortedVersions = useMemo(() => {
    if (versions) {
      let list = [...versions].sort((a, b) => {
        let sortingValue;
        switch (sorting.prop) {
          case 'table': {
            sortingValue = tableName(b.tableId).localeCompare(tableName(a.tableId));
            break;
          }
          case 'status': {
            sortingValue = b.status.localeCompare(a.status);
            break;
          }
          case 'uploadDate': {
            sortingValue = b.uploadTime.getTime() - a.uploadTime.getTime();
            break;
          }
          default:
            sortingValue = 0;
            break;
        }
        if (sorting.descending) sortingValue = sortingValue * -1;

        return sortingValue;
      });
      return list;
    } else {
      return [];
    }
  }, [versions, sorting, tableName]);

  const prevList: any[] = usePrevious(sortedVersions);
  useEffect(() => {
    if (sortedVersions?.length !== prevList?.length) {
      ReactTooltip.rebuild();
    }
  }, [sortedVersions, prevList]);

  const ref = useRef();
  return (
    <div className={m.container}>
      <div className={m.page_header}>
        <h1 className={m.page_title}>Masterdata Versions</h1>
      </div>
      <Tooltip id={`cloud`} text={'Go to version in GCS'} />
      <Tooltip id={`copy`} text={'Copy version ID'} />
      <Tooltip id={`firestore`} text={'Go to version in Firestore'} />
      <Filters
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        baseFiltersList={[
          {
            type: 'date',
            id: 'uploadTime',
            name: 'Upload Date',
          },

          {
            type: 'option',
            id: 'status',
            name: 'Status',
            options: [
              { id: 'ready', name: 'Ready' },
              { id: 'deleted', name: 'Deleted' },
              { id: 'failed', name: 'Failed' },
              { id: 'ingesting', name: 'Ingesting' },
            ],
          },
          {
            type: 'option',
            id: 'tenantId',
            name: 'Tenant Id',
            options: [],
          },
        ]}
      />
      <div className={s.table}>
        <div className={s.header}>
          <button
            className={clsx(s.header_name, s.first)}
            onClick={() => handleSetSorting('status')}
          >
            <span>Status</span>
            {sorting.prop === 'status' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <div className={clsx(s.header_name, s.second)}>
            <span>Version Id</span>
          </div>

          <div className={clsx(s.header_name, s.fourth)}>
            <span> Tenant Id</span>
          </div>
          <button
            className={clsx(s.header_name, s.third)}
            onClick={() => handleSetSorting('table')}
          >
            <span> Table Name</span>
            {sorting.prop === 'table' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(s.header_name, s.fifth)}
            onClick={() => handleSetSorting('uploadDate')}
          >
            <span> Upload Date</span>
            {sorting.prop === 'uploadDate' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <div className={clsx(s.sixth)}>
            <span> Actions</span>
          </div>
        </div>
        <Tooltip id={`cloud`} text={'Go to version in GCS'} />
        <Tooltip id={`copy`} text={'Copy table ID'} />
        <Tooltip id={`firestore`} text={'Go to version in Firestore'} />
        <div className={s.body} ref={ref}>
          {sortedVersions.map((version) => {
            return (
              <div
                key={version.id}
                style={{
                  paddingBottom: 4,
                  paddingTop: 4,
                  width: '100%',
                }}
              >
                <VersionRow version={version} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Versions;
