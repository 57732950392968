import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { cloneDeep } from 'lodash';
import { getUserToken } from '../shared/helpers';
import {
  GetModelResponse,
  GetTypeResponse,
  Inbox,
  Model,
  ModelVersionDetails,
  Router,
  Tenant,
} from '../shared/models';
import { AppThunk } from './store';

interface ModelsState {
  models: Model[];
  modelsServerState: Model[];
  modelsLoaded?: boolean;
  loadingVersionsIds: string[];
  stepNextPageTokens?: Record<string, string>;
  saveState: 'no-changes' | 'changes' | 'saving';
  activeTenant?: Tenant;
}

const initialState: ModelsState = {
  models: [],
  loadingVersionsIds: [],
  modelsServerState: [],
  saveState: 'no-changes',
  stepNextPageTokens: {},
};

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),
    setModels: (state, action: PayloadAction<Model[]>) => {
      state.models = action.payload;
    },
    setModelsServerState: (state, action: PayloadAction<Model[]>) => {
      state.modelsServerState = action.payload;
    },
    setModelsLoaded: (state, action: PayloadAction<boolean>) => {
      state.modelsLoaded = action.payload;
    },
    setLoadingVersionsIds: (state, action: PayloadAction<string[]>) => {
      state.loadingVersionsIds = action.payload;
    },
    setStepNextPageTokens: (state, action: PayloadAction<Record<string, string>>) => {
      state.stepNextPageTokens = action.payload;
    },
    setSaveState: (state, action: PayloadAction<'no-changes' | 'changes' | 'saving'>) => {
      state.saveState = action.payload;
    },
    setActiveTenant: (state, action: PayloadAction<Tenant>) => {
      state.activeTenant = action.payload;
    },
  },
});

let modelSource;

export const getModels =
  (activeTenant: Tenant, activeInbox?: Inbox, activeRouter?: Router): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!getState().models.modelsLoaded) {
      modelSource?.cancel('Request cancelled');
    }
    dispatch(modelsSlice.actions.setModels([]));
    dispatch(modelsSlice.actions.setModelsServerState([]));
    dispatch(modelsSlice.actions.setModelsLoaded(false));
    let url;
    if (activeInbox) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${activeTenant.id}/inboxes/${activeInbox.id}/workflows/${activeInbox.workflowVersion}/models`;
    } else if (activeRouter) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${activeTenant.id}/routers/${activeRouter.id}/workflows/models`;
    }
    modelSource = axios.CancelToken.source();

    axios
      .get(url, {
        cancelToken: modelSource.token,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      })
      .then((res) => {
        const data: GetModelResponse[] = camelcaseKeys(res.data?.results, { deep: true });
        const mappedModels = data.map((resp) => {
          return {
            typeId: resp.stepId,
            path: resp.path,
            name: resp.name,
          };
        });
        dispatch(modelsSlice.actions.setModels(mappedModels));
        dispatch(modelsSlice.actions.setModelsServerState(mappedModels));
        dispatch(modelsSlice.actions.setModelsLoaded(true));
      });
  };

export const processVersions = (
  data: GetTypeResponse,
  typeId: string,
  loadingVersionsIds: string[],
  getState: () => any,
  dispatch: any
) => {
  const modelsCopy = cloneDeep(getState().models.models);
  const existingTokens = cloneDeep(getState().models.stepNextPageTokens);
  const currentIndex = modelsCopy.findIndex((e) => e.typeId === typeId);
  const currentModel = cloneDeep(modelsCopy[currentIndex]);

  const mappedVersions = currentModel.modelVersions ?? {};

  data.versions.forEach((version) => {
    mappedVersions[version.id] = {
      ...version,
      createTime: version.createTime ? new Date(version.createTime) : null,
      updateTime: version.updateTime ? new Date(version.updateTime) : null,
      trafficPercentage: version.trafficPercentage ?? 0,
      percentageLocked: version.trafficPercentage
        ? version.trafficPercentage !== 0
        : false,
    };
  });

  currentModel.modelVersions = mappedVersions;
  currentModel.nextPageToken = data.versionsNextPageToken;

  modelsCopy[currentIndex] = currentModel;
  const tokens = existingTokens ?? {};
  tokens[typeId] = data.versionsNextPageToken;
  dispatch(modelsSlice.actions.setModels(modelsCopy));
  dispatch(modelsSlice.actions.setModelsServerState(modelsCopy));
  dispatch(modelsSlice.actions.setStepNextPageTokens(tokens));
};
export const getVersions =
  (
    activeTenant: Tenant,
    typeId: string,
    token?: string,
    activeInbox?: Inbox,
    activeRouter?: Router
  ): AppThunk =>
  async (dispatch, getState) => {
    let res;
    const b = await getUserToken();
    let url;
    const loadingVersionsIds = getState().models.loadingVersionsIds;

    dispatch(modelsSlice.actions.setLoadingVersionsIds([...loadingVersionsIds, typeId]));
    if (activeInbox) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${activeTenant.id}/inboxes/${activeInbox.id}/workflows/${activeInbox.workflowVersion}/models/${typeId}?page_size=10`;
    } else if (activeRouter) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${activeTenant.id}/routers/${activeRouter.id}/workflows/models/${typeId}`;
    }
    const params = {};
    params['page_size'] = 10;
    params['serving'] = true;
    if (token) {
      params['page_token'] = token;
    }
    if (!token) {
      res = await axios.get(url, {
        params,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      });
      if (res.status !== 200) return;
      const data: GetTypeResponse = camelcaseKeys(res.data, { deep: true });
      processVersions(data, typeId, loadingVersionsIds, getState, dispatch);
    }
    params['page_size'] = 10;
    params['serving'] = false;

    res = await axios.get(url, {
      params,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    });
    if (res.status !== 200) return;
    const data: GetTypeResponse = camelcaseKeys(res.data, { deep: true });
    processVersions(data, typeId, loadingVersionsIds, getState, dispatch);
    dispatch(
      modelsSlice.actions.setLoadingVersionsIds(
        loadingVersionsIds.filter((e) => e !== typeId)
      )
    );
  };

export const patchPercentages =
  (activeTenant: Tenant, activeInbox?: Inbox, activeRouter?: Router): AppThunk =>
  async (dispatch, getState) => {
    dispatch(modelsSlice.actions.setSaveState('saving'));
    const models = getState().models.models;
    const promises = [];

    models.forEach((model) => {
      if (model.modelVersions)
        promises.push(
          dispatch(
            patchType(
              activeTenant,
              model.typeId,
              model.modelVersions,
              activeInbox,
              activeRouter
            )
          )
        );
    });

    Promise.all(promises)
      .then((res) => {
        dispatch(modelsSlice.actions.setModelsServerState(models));
        dispatch(modelsSlice.actions.setSaveState('no-changes'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const patchType =
  (
    activeTenant: Tenant,
    typeId: string,
    versionDetails: Record<string, ModelVersionDetails>,
    activeInbox?: Inbox,
    activeRouter?: Router
  ): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();

    let url;
    if (activeInbox) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${activeTenant.id}/inboxes/${activeInbox.id}/workflows/${activeInbox.workflowVersion}/models/${typeId}`;
    } else if (activeRouter) {
      url = `${process.env.REACT_APP_PAPERBOX_BACKEND_URL}/tenants/${activeTenant.id}/routers/${activeRouter.id}/workflows/models/${typeId}`;
    }
    const mapped = Object.entries(versionDetails)
      .filter(([k, v]) => v.trafficPercentage !== 0)
      .map(([k, v]) => {
        return { id: k, traffic_percentage: v.trafficPercentage };
      });
    return axios.patch(
      url,
      { versions: mapped },
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      }
    );
  };
