import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import ReprocessModal from '../../shared/components/modal/ReprocessModal';
import { Inbox } from '../../shared/models';
import { useSelector } from '../../store/store';
import st from '../stats/stats.module.scss';
import s from './tenant-management.module.scss';

interface Props {
  tenantId: string;
  inbox: Inbox;
  openDocs: number;
  tenantOpenDocs: number;
}

const InboxRow: React.FC<Props> = ({ tenantId, inbox, openDocs, tenantOpenDocs }) => {
  const [isReprocessModalOpen, setIsReprocessModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      {isReprocessModalOpen && (
        <ReprocessModal
          tenantId={tenantId}
          inboxes={[inbox]}
          isOpen={isReprocessModalOpen}
          setIsOpen={setIsReprocessModalOpen}
        />
      )}
      <div className={s.row}>
        <span className={clsx(s.title, s.first)}>{inbox.name}</span>
        <div className={s.second}>
          <div className={st.percentage_wrapper}>
            <div
              className={st.percentage_inner}
              style={{
                width: `${(openDocs / tenantOpenDocs) * 100 || 0}%`,
              }}
            ></div>
          </div>
          {openDocs ?? 0}
        </div>
        <div className={s.third}>
          {user.role === 'superUser' && (
            <button onClick={() => setIsReprocessModalOpen(true)}>
              <ReloadIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default InboxRow;
