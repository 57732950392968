import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './slider.module.scss';

interface Props {
  value: number;
  max: number;
  min: number;
  onChange: (value: number) => void;
  hasInput?: boolean;
  inputPosition?: 'left' | 'right';
  disabled?: boolean;
  color?: string;
}

const Slider: React.FC<Props> = ({
  value,
  max,
  min,
  onChange,
  inputPosition = 'right',
  hasInput = false,
  disabled = false,
  color = '#0085FF',
}) => {
  const percentageValue = useMemo(() => {
    return (value / max) * 100;
  }, [value, max]);

  const style = useMemo(() => {
    return { '--color': color } as any;
  }, [color]);

  return (
    <div
      style={style}
      className={clsx(s.container, { [s.container__left]: inputPosition === 'left' })}
    >
      <input
        data-color={color}
        disabled={disabled}
        style={{
          background: `linear-gradient(to right, ${
            disabled ? color + '0f' : color
          }  0%, ${
            disabled ? color + '0f' : color
          } ${percentageValue}%,  #1b1e21 ${percentageValue}%, #1b1e21 100%)`,
        }}
        id="threshold"
        className={s.slider}
        type="range"
        onChange={(e) => onChange(parseInt(e.target.value))}
        value={value}
        max={max}
        min={min}
      />
      {hasInput && (
        <input
          disabled={disabled}
          max={max}
          min={min}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
          type="number"
          className={s.number}
        />
      )}
    </div>
  );
};

export default Slider;
