import React from 'react';
import { ReactComponent as CheckmarkIcon } from '../../../assets/checkmark.svg';
import s from './checkbox.module.scss';

const Checkbox: React.FC<
  React.HTMLProps<HTMLInputElement> & { indeterminate?: boolean }
> = ({ checked, indeterminate, onClick, ...rest }) => {
  return (
    <div className={s.container}>
      <input
        ref={(input) => {
          if (input && indeterminate != null) {
            input.indeterminate = indeterminate;
          }
        }}
        data-testid="checkbox-input"
        type="checkbox"
        className={s.hidden}
        checked={checked}
        onChange={() => null}
        {...rest}
      />
      <div data-testid="checkbox" onClick={onClick} className={s.checkbox} />
      <CheckmarkIcon className={s.checkmark} />
      <div className={s.indeterminate} />
    </div>
  );
};

export default Checkbox;
