import React from 'react';
import ReactTooltip from 'react-tooltip';
import s from './tooltip.module.scss';

interface Props {
  id: string;
  text: string;
}

const Tooltip: React.FC<Props> = ({ id, text }) => {
  return (
    <ReactTooltip
      id={id}
      className={s.container}
      backgroundColor={'#fff'}
      arrowColor={'transparent'}
      place="bottom"
      effect="solid"
    >
      <span>{text}</span>
    </ReactTooltip>
  );
};

export default Tooltip;
