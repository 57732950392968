import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CSSTransition from 'react-transition-group/CSSTransition';
import { ReactComponent as NotificationIcon } from '../../../assets/notification.svg';
import { useNotification } from '../../hooks/useNotification';
import s from './notification.module.scss';

interface Props {}

//TODO: Add support for multiple notifications

let interval;
const Notification: React.FC<Props> = (props) => {
  const { isActive, content, notificationType } = useNotification();
  const [remainingTime, setRemainingTime] = useState(3000);

  useEffect(() => {
    if (isActive && remainingTime === 3000) {
      interval = setInterval(() => {
        setRemainingTime((state) => state - 10);
      }, 10);
    }
  }, [remainingTime, isActive]);

  useEffect(() => {
    if (!isActive) {
      clearInterval(interval);
      setRemainingTime(3000);
    }
  }, [isActive]);

  return (
    <>
      <CSSTransition
        in={isActive}
        unmountOnExit
        mountOnEnter
        classNames="notification"
        timeout={300}
      >
        <div
          className={clsx(
            s.container,
            { [s.container__error]: notificationType === 'error' },
            { [s.container__warning]: notificationType === 'warning' }
          )}
        >
          <div className={s.left}>
            <NotificationIcon className={s.icon} />
          </div>
          {remainingTime !== 3000 && (
            <div className={s.counter} style={{ width: (220 / 3000) * remainingTime }} />
          )}

          <div className={s.text}>
            <p>{content}</p>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Notification;
