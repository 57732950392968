import React, { useEffect, useState } from 'react';
import Select, { Props, Theme } from 'react-select';

interface MyProps {
  testId?: string;
}
const Dropdown: React.FC<Props & MyProps> = ({
  className,
  options,
  isMulti,
  ...rest
}) => {
  const [activeOptions, setActiveOptions] = useState([]);

  useEffect(() => {
    if (isMulti) {
      setActiveOptions([{ label: 'Select All', value: 'all' }, ...options]);
    } else {
      setActiveOptions([...options]);
    }
  }, [options, isMulti]);

  const theme = (defaultTheme: Theme) => {
    return {
      ...defaultTheme,
      colors: {
        ...defaultTheme.colors,
        neutral0: '#333539',
        neutral5: '#333539',
        neutral10: '#0085FF',
        neutral20: '#333539',
        neutral30: '#333539',
        neutral40: '#333539',
        neutral50: '#D7DEE8',
        neutral80: 'white',
        primary: '#868894',
        primary25: '#333539',
        primary50: 'white',
        neutral90: '#333539',
      },
    };
  };
  return (
    <Select
      styles={{
        control: (provided) => {
          return {
            ...provided,
            minHeight: 42,
            maxHeight: 100,
            overflow: 'auto',
          };
        },
        multiValue: (provided) => {
          return {
            ...provided,
            background: 'linear-gradient(317.38deg, #2753A9 1.68%, #0085FF 149.06%)',
          };
        },
        multiValueRemove: (provided) => {
          return {
            ...provided,
            cursor: 'pointer',
            color: 'white',
            backgroundColor: 'transparent',
            paddingRight: 5,
            ':hover': {
              color: 'red',
            },
          };
        },
        multiValueLabel: (provided) => {
          return {
            ...provided,
            borderRadius: 7,
            color: 'white',
            outline: 'none',
            background: 'transparent',
            paddingLeft: 10,
          };
        },
        option: (provided, state) => {
          const { isFocused, isSelected } = state;
          return {
            ...provided,
            display: 'flex',
            margin: 5,
            paddingInline: 10,
            paddingBlock: 7,
            width: 'calc(100% - 10px)',
            borderRadius: 7,
            cursor: 'pointer',
            background: isFocused
              ? 'linear-gradient(317.38deg, #2753A9 1.68%, #0085FF 149.06%)'
              : isSelected
              ? 'linear-gradient(317.38deg, #2753A9 1.68%, #0085FF 149.06%)'
              : provided.backgroundColor,
            color: provided.color,
            ':hover': {
              color: 'white',
              background: 'linear-gradient(317.38deg, #2753A9 1.68%, #0085FF 149.06%)',
            },
          };
        },
      }}
      theme={theme}
      isMulti={isMulti}
      options={activeOptions}
      {...rest}
    />
  );
};

export default Dropdown;
