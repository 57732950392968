import { useEffect, useState } from 'react';
import { getStats } from '../../store/statsSlice';
import { useDispatch, useSelector } from '../../store/store';

export const useStats = (fetchTrigger: number) => {
  const stats = useSelector((state) => state.stats.stats);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getStats());
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, fetchTrigger]);

  return { stats, error, loading };
};
