import React, { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Notification from '../../shared/components/notification/Notification';
import { getCurrentEnvCode } from '../../shared/helpers';
import { useAuth } from '../../shared/hooks/useAuth';
import { usePendingDocuments } from '../../shared/hooks/usePendingDocuments';
import authSlice from '../../store/authSlice';
import { useDispatch, useSelector } from '../../store/store';
import { getUsers } from '../../store/usersSlice';
import Login from '../login/Login';
import Versions from '../masterdata/Versions';
import PendingDocuments from '../pending-documents/PendingDocuments';
import Stats from '../stats/Stats';
import TenantManagement from '../tenant-management/TenantManagement';
import Users from '../users/Users';
import s from './core.module.scss';
import Sidebar from './sidebar/Sidebar';

interface Props {}

const Core: React.FC<Props> = (props) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const olympusAdminUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    document.title = `Olympus | ${getCurrentEnvCode().toUpperCase()}`;
  }, []);

  const location = useLocation();
  const dispatch = useDispatch();
  const { user, isAuthenticating, authError } = useAuth();

  useEffect(() => {
    if (olympusAdminUser && olympusAdminUser.role === 'superUser' && isLoggedIn) {
      dispatch(getUsers());
    }
  }, [dispatch, isLoggedIn, olympusAdminUser]);

  const isUserLoggedIn = useMemo(() => user && isLoggedIn, [isLoggedIn, user]);

  usePendingDocuments(isUserLoggedIn, []);

  useEffect(() => {
    if (!user && !isLoggedIn && !isAuthenticating)
      dispatch(authSlice.actions.setIsLoggedIn(false));
  }, [dispatch, isAuthenticating, isLoggedIn, user]);

  if (isAuthenticating) return <main />;
  if (!user || !isLoggedIn || authError) {
    return (
      <Routes>
        <Route path={'/'} element={<Login />} />
        <Route path={'*'} element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return (
    <div id={'#appElement'} className={s.container}>
      {!location.pathname.includes('login') && <Sidebar />}
      <main>
        <Routes>
          <Route path={'/'} element={<Navigate replace to="/stats" />} />
          <Route path={'tenants'} element={<TenantManagement />} />
          <Route path={'pending'} element={<PendingDocuments />} />
          <Route path={'stats'} element={<Stats />} />
          {olympusAdminUser.role === 'superUser' && (
            <>
              <Route path={'users'} element={<Users />} />
              <Route path={'masterdata'} element={<Versions />} />
            </>
          )}
          <Route path={'login'} element={<Login />} />
          <Route path={'*'} element={<Navigate replace to="/stats" />} />
        </Routes>
      </main>
      <Notification />
    </div>
  );
};

export default Core;
